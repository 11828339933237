/* ./src/index.css */
@tailwind base;

@layer base {
  h1 {
    @apply text-2xl font-bold;
  }
  h2 {
    @apply text-xl;
  }
}

@tailwind components;
@tailwind utilities;

body {
  background-color: papayawhip;
}

ul {
  list-style-type: disc;
  list-style-position: inside;
}
ol {
  list-style-type: decimal;
  list-style-position: inside;
}
ul ul,
ol ul {
  list-style-type: circle;
  list-style-position: inside;
  margin-left: 15px;
}
ol ol,
ul ol {
  list-style-type: lower-latin;
  list-style-position: inside;
  margin-left: 15px;
}
